import Navbar from './Navbar';
import Footer from './Footer';
import Menu from './Menu';
import Home from './Home';
import Login from './Login';
import Logout from  './Logout';

import { BrowserRouter, Routes, Route } from 'react-router-dom';

import { AuthProvider, RequireAuth } from "./Auth";

function App() {
  return (
    <AuthProvider>
      <BrowserRouter>
        <div className="App">
          <Navbar />
          <Menu />
          <div className="content flex justify-center">
            <Routes>
              <Route element={<RequireAuth />}>
                <Route path='/home'  element={<Home />} />
              </Route>

              <Route path='/login'  element={<Login />} />
              <Route path='/logout'  element={<Logout />} />

              <Route path="*" element={<Login />} />
            </Routes>
          </div>
          <Footer />
        </div>
      </BrowserRouter>
    </AuthProvider>
  );
}

export default App;
