import { useState, useEffect } from "react";
import { IP } from './config.js';


const Menu = () => {
    useEffect(() => {

    }, []);

    return (
        <div className="">

        </div>
    ); 
}
 
export default Menu;
