import { Link } from 'react-router-dom';

const Footer = () => {
  return (
      <>
        <footer className="footer p-10 bg-base-200 text-base-content">
          <div>
            <span className="footer-title">Cognito</span> 
            <a href="https://blog.xa0.de/list" className="link link-hover">Blog</a> 
          </div> 
          <div>
            <span className="footer-title">Company</span> 
            {/*<Link to="/about" className="link link-hover">About</Link>*/}
            <Link to="/contact" className="link link-hover">Contact</Link>
          </div> 
          <div>
            <span className="footer-title">Legal</span> 
            <Link to="/contact" className="link link-hover">Imprint</Link>
            <Link to="/privacy" className="link link-hover">Privacy policy</Link> 
            {/*<a className="link link-hover">Terms of use</a>*/}
          </div>
        </footer> 
        <footer className="footer px-10 py-4 border-t bg-base-200 text-base-content border-base-300">
          <div className="items-center grid-flow-col">
            <Link to="/home">
              Cognito
            </Link>
            <p><a href="https://overpassage.com/">Digital Express Post GmbH</a><br/>© 2023</p>
          </div> 
          <div className="md:place-self-center md:justify-self-end">
            <div className="grid grid-flow-col gap-4">
              <a href="https://twitter.com/instancelabs"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" className="fill-current"><path d="M24 4.557c-.883.392-1.832.656-2.828.775 1.017-.609 1.798-1.574 2.165-2.724-.951.564-2.005.974-3.127 1.195-.897-.957-2.178-1.555-3.594-1.555-3.179 0-5.515 2.966-4.797 6.045-4.091-.205-7.719-2.165-10.148-5.144-1.29 2.213-.669 5.108 1.523 6.574-.806-.026-1.566-.247-2.229-.616-.054 2.281 1.581 4.415 3.949 4.89-.693.188-1.452.232-2.224.084.626 1.956 2.444 3.379 4.6 3.419-2.07 1.623-4.678 2.348-7.29 2.04 2.179 1.397 4.768 2.212 7.548 2.212 9.142 0 14.307-7.721 13.995-14.646.962-.695 1.797-1.562 2.457-2.549z"></path></svg></a> 
              <a href="https://www.linkedin.com/company/digital-express-post/"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="feather feather-linkedin"><path d="M16 8a6 6 0 0 1 6 6v7h-4v-7a2 2 0 0 0-2-2 2 2 0 0 0-2 2v7h-4v-7a6 6 0 0 1 6-6z"></path><rect x="2" y="9" width="4" height="12"></rect><circle cx="4" cy="4" r="2"></circle></svg></a>
            </div>
          </div>
        </footer>
      </>
    );
}
 
export default Footer;
