// import logo from './logo.png'
// import logo_long from './logo_long.png'
import { Link } from 'react-router-dom';
import { useState, useEffect } from 'react';
import { IP } from './config.js';

import { useAuth } from "./Auth";

const Navbar = () => {
   const { user, setUser } = useAuth();

  useEffect(() => {
      console.log(user);
  }, [user]);

  return (
        <div className="navbar shadow-2xl bg-base-200 roundedborders">
          <div className="flex-1">
            <Link to="/home">
              <img src="https://images.squarespace-cdn.com/content/v1/5ece2ac40514bb77afb1018d/e4858477-9488-40dd-aa1e-1dac3e579720/axiros_header.png?format=1500w" className="App-logo" alt="logo" />
            </Link>
          </div>
          {user && user.length >= 7 ?
            <div className="flex-none">
              <ul className="menu menu-horizontal px-1">
              <li className="mx-2"><Link to="/home">Home</Link></li>
              <li className="mx-2"><Link to="/logout">Logout</Link></li>
              </ul>
            </div>
          :
          <div className='flex-none'>
            <ul className="menu menu-horizontal px-1">
              <li className="mx-2"><Link to="/login">Login</Link></li>
            </ul>
            </div>
          }
        </div>
    );
}
 
export default Navbar;
