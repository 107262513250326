import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { IP } from './config.js';

import { useAuth } from "./Auth";

const Login = () => {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const navigate = useNavigate();

    const { setUser } = useAuth();

    const handleSubmit = (e) => {
        e.preventDefault();
        const user = {email, password};

        fetch(IP + '/login', {
            method: 'POST',
            headers: { "Authorization" : 'Basic',
                       "Content-Type" : 'application/json',
                       "Access-Control-Allow-Origin": 'true'},
            mode: 'cors',
            credentials: 'include',
            body: JSON.stringify(user)
            }).then(res => {
                // console.log(res.status);
                res.json().then(data => {
                    // console.log(data)
                    setUser(data);
                    navigate('/home');
                });
            }).catch((error) => {
                alert("Incorrect password or email");
            });
    }
    
    return ( 
        <div className="hero my-10">
          <div className="hero-content flex-col lg:flex-row-reverse">
            <div className="text-center lg:text-left ml-5">
              <h1 className="text-5xl font-bold">Login</h1>
              <p className="py-6">
                Login and ask away.
              </p>
            </div>
            <div className="card flex-shrink-0 w-full max-w-sm shadow-2xl bg-base-200">
              <div className="card-body">
                <form onSubmit={ handleSubmit }>
                <div className="form-control">
                  <label className="label">
                    <span className="label-text">Email</span>
                  </label>
                  <input placeholder="email" className="input input-bordered w-full max-w-xs"
                      type="email"
                      required
                      value={ email }
                      onChange={(e) => setEmail(e.target.value)}
                  />
                </div>
                <div className="form-control">
                  <label className="label">
                    <span className="label-text">Password</span>
                  </label>
                  <input placeholder="password" className="input input-bordered w-full max-w-xs"
                      type="password" 
                      required
                      value={ password }
                      onChange={(e) => setPassword(e.target.value)}
                  />
                  <label className="label">
                    <a href="/forgot" className="label-text-alt link link-hover">Forgot password?</a>
                  </label>
                </div>
                <div className="form-control mt-6">
                  <button className="btn btn-primary">Login</button>
                </div>
                </form>
              </div>
            </div>
          </div>
        </div>
     );
}

export default Login;
