import { useState, useEffect } from "react";
import { IP } from './config.js';
import { useNavigate } from 'react-router-dom';


const Home = () => {
    const [msg, setMsg] = useState("");
    const [messages, setMessages] = useState([]);
    const [loading, setLoading] = useState(false);
    const navigate = useNavigate();

    function chat() {
      setLoading(true);
      fetch(IP + "/chat", {
        method: "POST",
        mode: 'cors',
        credentials: 'include',
        headers: { "Authorization" : 'Basic',
                   "Content-Type" : 'application/json',
                   "Access-Control-Allow-Origin": 'true'},
        body: JSON.stringify({"data": msg})
      }).then((resp) => {
        resp.json().then((data) => {
          setLoading(false);
          console.log(msg, data);
          setMessages([...messages, msg, data['response']]);
          setMsg("");
        });
      });
    }

    function handleKeyPress(evt) {
      if (evt.key === 'Enter') {
        chat();
      }
    }

    useEffect(() => {
    }, []);

    return (
        <div className="home">

        <div className="alert alert-info shadow-lg">
          <div>
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" className="stroke-current flex-shrink-0 w-6 h-6"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M13 16h-1v-4h-1m1-4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"></path></svg>
            <span>This is a chat bot that knows a lot about Axiros. Ask about specific persons at Axiros, about product lines, about processes and operations. Note that the chat bot does not keep context across multiple messages as of yet. This is an <b>alpha</b>.</span>
          </div>
        </div>

{ messages.length > 0 ?
        <div className="divider"></div>
: ""}

{ messages.map(function (message, index) {
    return (
        <>
{ index % 2 === 0 ?
        <div className="chat chat-end">
          <div className="chat-bubble chat-bubble-success">{ message }</div>
        </div>
:
        <div className="chat chat-start">
          <div className="chat-bubble chat-bubble-success">{ message }</div>
        </div>
}
        </>
    );
})}

        <div className="divider"></div>

        <div className="form-control">
          <div className="input-group">
            <input placeholder="Ask anything about Axiros and press enter" className="input input-bordered w-full max-w-xs"
                required
                value={ msg }
                onChange={(e) => setMsg(e.target.value)}
                onKeyPress={(e) => handleKeyPress(e)}
            />
            <button className={`btn btn-circle ` + (loading ? "loading btn-disabled" : "")} onClick={() => chat()}>
{ !loading ?
            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="feather feather-send"><line x1="22" y1="2" x2="11" y2="13"></line><polygon points="22 2 15 22 11 13 2 9 22 2"></polygon></svg>
: ""}
            </button>
          </div>
        </div>

        </div>
    ); 
}
 
export default Home;
